import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useReasonList() {
  // Use toast
  const toast = useToast()

  const refReasonListTable = ref(null)

  const perPage = ref(5)
  const totalReasons = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refReasonListTable.value ? refReasonListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReasons.value,
    }
  })

  const refetchData = () => {
    refReasonListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchReasons = (ctx, callback) => {
    store
      .dispatch('reason/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
      })
      .then(response => {
        const { reasons, total } = response.data.data

        callback(reasons)
        totalReasons.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting reasons list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchReasons,
    perPage,
    currentPage,
    totalReasons,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReasonListTable,
    refetchData,

  }
}
